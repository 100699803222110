import React from "react";

const SubTitle = ({
  title,
  className,
  animationType,
  animationOffset,
  animationDuration,
  innerClass,
}) => {
  return (
    <span className={`relative ${className}`}>
      {title}
      <span
        data-aos={animationType}
        data-aos-offset={animationOffset}
        data-aos-easing="ease-in-sine"
        data-aos-duration={animationDuration}
        className={`bg-primary h-1 rounded-15 w-full block absolute ${innerClass} `}
      ></span>
    </span>
  );
};

export default SubTitle;
