import React from "react";
import { useEffect } from "react";

export const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="py-24 md:py-32 w-full bg-[#EEEEEE]">
        <div className="container">
          <div>
            <h1 className="text-4xl md:text-6xl font-bold text-primary">
              Privacy Notice
            </h1>
          </div>
          <div className="mt-8 md:mt-16 md:mx-32">
            <div className="">
              <h2 className="text-xl md:text-xl font-bold text-left text-primary">
                TygrLabs Privacy Policy Notice
              </h2>
              <p className="mt-4 md:mt-6 text-lg md:text-xl text-secondary">
                This Privacy Policy Notice describes how TygrLabs ("we," "us,"
                or "our") collects, uses, and discloses your personal
                information when you visit our website (the "Website"). It also
                outlines your privacy rights and how the law safeguards them. We
                may use your personal data to get in touch with you, offer
                support, and enhance our Service. By using our Service, you
                consent to us collecting and using your information as described
                in this Privacy Policy.
              </p>
            </div>
            {/* Defination */}
            <div className="mt-8 md:mt-16">
              <h2 className="text-1xl md:text-xl font-bold text-left text-primary">
                Definition
              </h2>
              <h2 className="mt-4 md:mt-6 text-lg md:text-xl font-bold text-left text-secondary">
                For the purposes of this Privacy Policy:
              </h2>
              <div className="mt-4 md:mt-6 text-md md:text-xl text-secondary mx-10">
                <ul className="list-disc">
                  <li>
                    Company: This refers to TygrLabs, located at Level 5, Plot
                    No 11, Rd No 113/A, Gulshan-2, Dhaka 1212.
                  </li>
                  <li>
                    Device: This includes any device like a computer, cellphone,
                    or digital tablet that can access our Service.
                  </li>
                  <li>
                    Personal Data: This term covers any information that relates
                    to an identified or identifiable individual.
                  </li>
                  <li>
                    Service: "Service" refers to any of our advertisements,
                    websites, applications, and social media platforms,
                    including but not limited to Facebook, LinkedIn, and
                    Instagram.
                  </li>
                  <li>
                    Service Provider: A Service Provider is any person or
                    entity, whether natural or legal, who processes data on
                    behalf of the Company. This includes third-party companies
                    or individuals employed or engaged by the Company to help
                    provide, facilitate, or analyze the Service.
                  </li>
                  <li>
                    Usage Data: Usage Data refers to data collected
                    automatically, either by using the Service or from the
                    Service infrastructure itself. This could include
                    information like the duration of a page visit.
                  </li>
                  <li>
                    You: "You" refers to the individual accessing or using our
                    Service. It can also refer to the company or other legal
                    entity on behalf of which that individual is accessing or
                    using the Service, if applicable.
                  </li>
                </ul>
              </div>
            </div>
            <div className="mt-8 md:mt-16">
              <h2 className="text-xl md:text-1xl font-bold text-left text-primary">
                Collecting and Using Your Personal Data
              </h2>
              <h2 className="mt-8 text-xl md:text-1xl font-bold text-left text-primary">
                Types of Data Collected
              </h2>
              <h2 className="mt-4 md:mt-6 text-lg md:text-xl font-bold text-left text-secondary">
                Personal Data
              </h2>
              <p className="mt-4 md:mt-6 text-lg md:text-xl text-secondary">
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you. This may include, but is not limited
                to:
              </p>
              <div className="mt-4 md:mt-6 text-md md:text-xl text-secondary mx-10">
                <ul className="list-disc">
                  <li>Email address</li>
                  <li>First name and last name</li>
                  <li>Phone number</li>
                  <li>Address, State, Province, ZIP/Postal code, City</li>
                  <li>Cookies and Usage Data</li>
                </ul>
              </div>
              <h2 className="mt-8 text-xl md:text-1xl font-bold text-left text-secondary">
                Information You Supply to Us:
              </h2>
              <p className="mt-4 md:mt-6 text-lg md:text-xl text-secondary">
                While using our Service, you may provide us with information
                about yourself. This could be done by filling in forms on our
                website or social media platforms, including Facebook, LinkedIn,
                and Instagram. For example, when you submit a contact form,
                inquiry form, Lead Gen Form, Instant Form, or Lead Generation
                Form on platforms like LinkedIn or Facebook.
              </p>
              <h2 className="mt-8 text-xl md:text-1xl font-bold text-left text-secondary">
                Log Data:
              </h2>
              <p className="mt-4 md:mt-6 text-lg md:text-xl text-secondary">
                Similar to many other websites, we may also collect information
                that your browser sends whenever you visit our Service or when
                you access the Service by or through a mobile device ("Usage
                Data"). This Usage Data may include information such as your
                computer's Internet Protocol address (e.g. IP address), browser
                type, browser version, the pages of our Service that you visit,
                the time and date of your visit, the time spent on those pages,
                unique device identifiers, and other diagnostic data.
              </p>
              <h2 className="mt-8 text-xl md:text-1xl font-bold text-left text-secondary">
                Third-party Tools and Services:
              </h2>
              <p className="mt-4 md:mt-6 text-lg md:text-xl text-secondary">
                We utilize third-party tools and/or services, like Facebook
                Pixel and Google Analytics, through which these third parties
                can gather, track, and analyze your data. These third parties
                may employ cookies, web beacons, and similar technologies to
                gather information about you and use it to offer you targeted
                advertising. Additionally, we employ remarketing/retargeting
                advertising services from third parties, enabling us to display
                ads to our website visitors on other platforms and websites.
              </p>

              <div className="mb-8 mt-8">
                <h2 className="text-lg md:text-xl font-bold text-left text-primary">
                  Use of Your Personal Data
                </h2>
                <p className="mt-4 text-base font-bold md:text-lg text-gray-800">
                  The Company may use your Personal Data for various purposes:
                </p>
                <ol className="list-decimal ml-8 text-lg md:text-xl">
                  <li className="mt-2">
                    To provide and maintain our Service, including monitoring
                    its usage.
                  </li>
                  <li className="mt-2">
                    To manage Your Account: This involves managing your
                    registration as a user of the Service. The Personal Data you
                    provide may grant you access to different features of the
                    Service available to registered users.
                  </li>
                  <li className="mt-2">
                    For the performance of a contract: This includes developing,
                    complying with, and executing purchase contracts for
                    products, items, or services you've bought, or any other
                    contract with us through the Service.
                  </li>
                  <li className="mt-2">
                    To contact You: We may contact you via email, telephone
                    calls, SMS, or other electronic means like push
                    notifications regarding updates or informative
                    communications related to the Service, including security
                    updates, when necessary or reasonable for implementation. We
                    may also contact you with newsletters, marketing, or
                    promotional materials.
                  </li>
                  <li className="mt-2">
                    To provide You with news, special offers, and general
                    information about other goods, services, and events that we
                    offer, similar to those you've already purchased or inquired
                    about, unless You have opted out of receiving such
                    information.
                  </li>
                  <li className="mt-2">
                    To manage Your Requests: This involves attending to and
                    managing your requests to Us.
                  </li>
                  <li className="mt-2">
                    For business transfers: We may use Your information in
                    evaluating or conducting mergers, divestitures,
                    restructurings, or other sales or transfers of some or all
                    of Our assets, whether as a going concern or as part of
                    bankruptcy, liquidation, or similar proceedings, where
                    Personal Data held by Us about our Service users is among
                    the assets transferred.
                  </li>
                  <li className="mt-2">
                    To optimize and personalize our Service and advertisements.
                  </li>
                  <li className="mt-2">
                    For other purposes: We may use Your information for purposes
                    such as data analysis, identifying usage trends, evaluating
                    the effectiveness of our promotional campaigns, and
                    enhancing our Service, products, services, marketing, and
                    your experience.
                  </li>
                </ol>
              </div>

              <div className="mb-8">
                <h2 className="text-lg md:text-xl font-bold text-left text-secondary">
                  Sharing Your Personal Information
                </h2>
                <ol className="list-decimal ml-8 text-lg md:text-xl">
                  <li className="mt-2">
                    With Service Providers: We may share Your personal
                    information with Service Providers to monitor and analyze
                    the use of our Service and to contact You.
                  </li>
                  <li className="mt-2">
                    For business transfers: We may share or transfer Your
                    personal information in connection with, or during
                    negotiations of, any merger, sale of Company assets,
                    financing, or acquisition of all or a portion of Our
                    business to another company.
                  </li>
                  <li className="mt-2">
                    With Affiliates: We may share Your information with Our
                    affiliates, requiring them to adhere to this Privacy Policy.
                    Affiliates include Our parent company and any subsidiaries,
                    joint venture partners, or other companies under common
                    control with Us.
                  </li>
                  <li className="mt-2">
                    With business partners: We may share Your information with
                    Our business partners to offer You certain products,
                    services, or promotions.
                  </li>
                  <li className="mt-2">
                    With other users: Any personal information you share or
                    interact with in public areas may be viewed by all users and
                    publicly distributed.
                  </li>
                  <li className="mt-2">
                    With Your consent: We may disclose Your personal information
                    for any other purpose with Your consent.
                  </li>
                </ol>
              </div>

              <div className="mb-8">
                <h2 className="text-lg md:text-xl font-bold text-left text-secondary">
                  Retention of Your Personal Data
                </h2>
                <p className="mt-2 text-lg md:text-xl">
                  The Company will keep Your Personal Data only for as long as
                  necessary for the purposes outlined in this Privacy Policy.
                  We'll retain and use Your Personal Data to meet our legal
                  obligations, such as retaining your data to comply with
                  applicable laws, resolve disputes, and enforce our legal
                  agreements and policies. Additionally, we will retain Usage
                  Data for internal analysis purposes.
                </p>
              </div>
              <div className="mb-8">
                <h2 className="text-lg md:text-xl font-bold text-left text-secondary">
                  Transfer of Your Personal Data
                </h2>
                <p className="mt-2 text-lg md:text-xl">
                  Your information, including Personal Data, is processed at the
                  Company’s operating offices and other places involved in
                  processing. This means that this information may be
                  transferred to and stored on computers located outside Your
                  state, province, country, or other governmental jurisdiction
                  where data protection laws may differ from those in Your
                  jurisdiction. By consenting to this Privacy Policy and
                  submitting such information, You agree to this transfer. The
                  Company will take all reasonable steps to ensure Your data is
                  treated securely and in accordance with this Privacy Policy.
                  No transfer of Your Personal Data will occur to an
                  organization or country unless adequate controls are in place,
                  including the security of Your data and other personal
                  information.
                </p>
              </div>
              <div className="mb-8">
                <h2 className="text-lg md:text-xl font-bold text-left text-secondary">
                  Disclosure of Your Personal Data
                </h2>
                <p className="mt-2 text-lg md:text-xl">
                  Law enforcement: Under certain circumstances, the Company may
                  be obligated to disclose Your Personal Data to law enforcement
                  if required by law.
                </p>
              </div>
              <div className="mb-8">
                <h2 className="text-lg md:text-xl font-bold text-left text-secondary">
                  Other Legal Requirements
                </h2>
                <p className="mt-2 text-lg md:text-xl">
                  The Company may disclose Your Personal Data in good faith
                  belief that such action is necessary to:
                </p>
                <ul className="list-disc ml-6 mt-2 text-lg md:text-xl">
                  <li>Comply with a legal obligation</li>
                  <li>
                    Protect and defend the rights or property of the Company
                  </li>
                  <li>
                    Prevent or investigate possible wrongdoing in connection
                    with the Service
                  </li>
                  <li>
                    Protect the personal safety of Users of the Service or the
                    public
                  </li>
                  <li>Protect against legal liability</li>
                </ul>
              </div>

              <div className="mb-8">
                <h2 className="text-lg md:text-xl font-bold text-left text-primary">
                  Security of Your Personal Data
                </h2>
                <p className="mt-2 text-lg md:text-xl">
                  While We take the security of Your Personal Data seriously,
                  it's important to note that no method of transmission over the
                  Internet or electronic storage is 100% secure. Although We
                  strive to use commercially acceptable means to protect Your
                  Personal Data, We cannot guarantee its absolute security.
                </p>
              </div>
              <div className="mb-8">
                <h2 className="text-lg md:text-xl font-bold text-left text-primary">
                  Cookies
                </h2>
                <p className="mt-2 text-lg md:text-xl">
                  Cookies are small files with a unique identifier that are sent
                  to your browser from a website and stored on your computer’s
                  hard drive. Like many sites, we use “cookies” to collect
                  information. You can instruct your browser to refuse all
                  cookies or to indicate when a cookie is being sent. However,
                  if you do not accept cookies, you may not be able to use some
                  portions of our Site.
                </p>
              </div>
              <div className="mb-8">
                <h2 className="text-lg md:text-xl font-bold text-left text-primary">
                  Links to Other Websites
                </h2>
                <p className="mt-2 text-lg md:text-xl">
                  Our Service may contain links to other websites not operated
                  by Us. If You click on a third-party link, You will be
                  directed to that third party’s site. We strongly advise You to
                  review the Privacy Policy of every site You visit. We have no
                  control over and assume no responsibility for the content,
                  privacy policies, or practices of any third-party sites or
                  services.
                </p>
              </div>
              <div className="mb-8">
                <h2 className="text-lg md:text-xl font-bold text-left text-primary">
                  Changes to this Privacy Policy
                </h2>
                <p className="mt-2 text-lg md:text-xl">
                  We may update Our Privacy Policy periodically. Any changes
                  will be notified by posting the new Privacy Policy on this
                  page. You are advised to review this Privacy Policy
                  periodically for any changes. Changes to this Privacy Policy
                  are effective when they are posted on this page. This Privacy
                  Policy was last updated on March 3rd, 2024.
                </p>
              </div>

              <div className="mt-8">
                <h2 className="text-xl md:text-1xl font-bold text-left text-primary">
                  Contact Us
                </h2>
                <p className="mt-2 text-lg md:text-xl">
                  If you have any questions about this Privacy Policy, you can
                  contact us via email at{" "}
                  <a href="mailto:hello@tygrlabs.co" className="text-blue-500">
                    hello@tygrlabs.co
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
