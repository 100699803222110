import { Outlet } from "react-router";
import Footer from "./components/footer";
import Header from "./components/header";

const Layout = ({ is404 }) => {
  return (
    <>
      {!is404 && <Header />}
      <Outlet />
      {!is404 && <Footer />}
    </>
  );
};
export default Layout;
