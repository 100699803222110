import React from "react";
import EastIcon from "@mui/icons-material/East";

const PageNotFound = () => {
  return (
    <section className="relative z-10 bg-secondary h-screen flex flex-col justify-center before:absolute before:bg-u-shape before:bg-no-repeat before:bg-contain before:-z-10 before:-top-40 before:right-0 before:rotate-180 after:w-[646px] after:h-[449px] after:absolute after:bg-u-shape after:bg-no-repeat after:-z-10 after:-bottom-20 after:-scale-x-100 after:left-0 before:w-[646px] before:h-[449px] overflow-hidden">
      <div className="container h-full text-center flex flex-col justify-center">
        <div className="w-full max-w-xs md:max-w-sm mb-8 mx-auto">
          <img
            src="assets/images/404.png"
            alt="404"
            className="w-full block object-contain object-center"
          />
        </div>
        <h2 className="md:text-4xl sm:text-3xl text-2xl leading-8 text-alabaster md:mb-36 mb-6">
          Something wrong!
        </h2>
        <a
          href="/"
          className="md:text-4xl sm:text-2xl text-xl leading-6 text-alabaster font-medium bg-primary px-11 py-6 rounded-51 flex items-center justify-center"
        >
          <span className="mr-4">Go to website</span>
          <EastIcon fontSize="large" />
        </a>
      </div>
    </section>
  );
};

export default PageNotFound;
