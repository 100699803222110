import React, { useState } from "react";
import Button from "./button";
import axios from "axios";
import Loader from "./loader";
import { Modal } from "./modal";
import { ToastContainer, toast } from "react-toastify";

const ApplyForm = (props) => {
  const { btnColor, title } = props;
  const [file, setFile] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPDFValid, setIsPDFValid] = useState(true);
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    title: title,
    email: "",
    message: "",
    githubProfile: "",
    linkedinProfile: "",
    cv: "",
    isApply: true,
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileName = file?.name || "";
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));

    // Check if the file is a PDF
    if (file && fileExtension !== ".pdf") {
      toast.error("Please upload a valid PDF file.");
      setIsPDFValid(false);
      setFile("");
      setFormData({ ...formData, cv: "" });
    } else {
      setIsPDFValid(true);
      setFile(
        fileName.length > 10
          ? `${fileName.slice(0, 10)}..${fileExtension}`
          : fileName
      );
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFormData({ ...formData, cv: reader.result });
      };
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      axios
        .post("https://tygrlabs-server.vercel.app/api/v1/send-email", formData)
        .then((res) => {
          setLoading(false);
          if (res.data.success) {
            setOpen(true);
            document.body.style.overflow = "hidden";
            setFormData({
              firstName: "",
              lastName: "",
              title: title,
              email: "",
              message: "",
              githubProfile: "",
              linkedinProfile: "",
              cv: "",
              isApply: true,
            });
            setFile("");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.response.data);
        });
    }
  };

  const validate = () => {
    let isValid = true;
    if (!formData.email) {
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      isValid = false;
    }
    if (!formData.firstName) {
      isValid = false;
    }
    if (!formData.lastName) {
      isValid = false;
    }
    if (!formData.cv) {
      isValid = false;
    }
    // Ensure the file is valid
    if (!isPDFValid) {
      isValid = false;
    }

    return isValid;
  };

  return (
    <div className="block">
      {loading && <Loader />}
      <input
        type="text"
        name="firstName"
        placeholder="First Name (Required)"
        className="mb-6 text-secondary placeholder-secondary rounded-42 w-full py-3 px-6"
        onChange={(e) => handleChange(e)}
      />
      <input
        name="lastName"
        placeholder="Last Name (Required)"
        className="mb-6 text-secondary placeholder-secondary rounded-42 w-full py-3 px-6"
        onChange={(e) => handleChange(e)}
      />
      <input
        name="email"
        placeholder="Email (Required)"
        className="mb-6 text-secondary/50  placeholder-secondary rounded-42 w-full py-3 px-6"
        onChange={(e) => handleChange(e)}
      />
      <textarea
        id="exampleFormControlTextarea13"
        placeholder="About you (Optional)"
        rows={6}
        name="message"
        className="mb-6 text-secondary/50 rounded-42 w-full p-6"
        onChange={(e) => handleChange(e)}
      />
      <input
        name="linkedinProfile"
        placeholder="LinkedIn Profile"
        className="mb-6 text-secondary/50 placeholder-secondary rounded-42 w-full py-3 px-6"
        onChange={(e) => handleChange(e)}
      />
      <input
        name="githubProfile"
        placeholder="GitHub Profile"
        className="mb-6 text-secondary/50 placeholder-secondary rounded-42 w-full py-3 px-6"
        onChange={(e) => handleChange(e)}
      />
      <div className="flex justify-between bg-white rounded-42 w-full py-2 px-2">
        <div className="my-auto">
          <h3 className="ml-4 text-secondary">Upload CV {"(Required)"}</h3>
        </div>
        <div className="flex items-center">
          <label
            htmlFor="cvUpload"
            className="bg-[#271E1C] text-white rounded-full px-6 py-1 mr-1 cursor-pointer"
          >
            {file ? file : "Upload (PDF ONLY)"}
          </label>
          <input
            name="cv"
            type="file"
            id="cvUpload"
            className="hidden"
            onChange={handleFileChange}
          />
        </div>
      </div>
      <div className="text-right">
        <Button
          action={(e) => handleSubmit(e)}
          type="arrow-btn"
          customClass={`mt-10 ${btnColor} ${
            !validate()
              ? "cursor-not-allowed opacity-50 pointer-events-none"
              : "cursor-pointer opacity-1 pointer-events-auto"
          }`}
          disabled={!validate()}
        >
          Submit
        </Button>
      </div>
      <Modal
        openModal={open}
        setOpenModal={(isOpen) => {
          setOpen(isOpen);
          if (!isOpen) {
            document.body.style.overflow = "auto";
          }
        }}
        title={"Thanks for applying"}
        description={"Your application has been submitted successfully"}
      />
    </div>
  );
};

export default ApplyForm;
