import { SwiperSlide } from "swiper/react";
import ApplyForm from "../components/apply-form";
import ContactForm from "../components/form-template";
import Slider from "../components/slider";
import data from "../data/apply-page-data.json";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const JobApply = () => {
  AOS.init({
    duration: 1000,
  });

  // read the id from the url query string
  const params = new URLSearchParams(document.location.search);
  const id = params.get("id");
  const jobData = data.find((item) => item.id == id);
  const {
    title,
    summary,
    about,
    positionOverview,
    technology,
    responsibilities,
    qualification,
    sliderData,
    niceToHave,
    benefits,
    timing,
    additional,
  } = jobData;
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/career#job");
  };
  return (
    <>
      <section className="py-24 md:py-40 w-full bg-[#EEEEEE]">
        <div className="container">
          <div>
            <h1 className="text-5xl md:text-6xl font-bold text-primary">
              {title}
            </h1>
            <div className="md:flex md:flex-wrap lg:justify-between md:justify-start lg:gap-x-4 h-full lg:h-[100px] rounded-15 py-10 px-10 text-white bg-primary mt-10">
              {summary.map((item, index) => (
                <div
                  key={index}
                  className=" md:text-center py-2 lg:py-0 flex gap-4"
                >
                  <img src={item.icon} className="h-8 w-8" alt="" />
                  <h3 className=" text-xl">{item.description}</h3>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white text-justify rounded-15 mt-10 w-full md:w-[80%] mx-auto">
            <div className="py-10 px-4 md:px-10">
              <h3 className="text-primary font-bold">About Us</h3>
              <p className="mt-4">{about}</p>
            </div>
          </div>
          <div className=" mt-4 text-justify w-full md:w-[80%] mx-auto">
            <div className="py-10 px-4 md:px-10">
              <h3 className="text-primary font-bold">Position Overview</h3>
              <p className="mt-4">{positionOverview}</p>
            </div>
          </div>
          {technology && (
            <div className="mt-4 text-justify overflow-hidden w-full md:w-[80%] mx-auto">
              <div className="py-10 px-4 md:px-10">
                <h3 className="text-primary font-bold">Key Technologies</h3>
                <ul className="list-none">
                  {technology.map((item, index) => (
                    <li key={index} className="relative pl-4 mt-1">
                      <span className="absolute left-0 top-0">-</span>
                      <span dangerouslySetInnerHTML={{ __html: item }}></span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <div className=" mt-4 w-full md:w-[80%] text-justify mx-auto">
            <div className="py-10 px-4 md:px-10">
              <h3 className="text-primary font-bold">Responsibilities</h3>
              <ul className="list-none">
                {responsibilities.map((item, index) => (
                  <li key={index} className="relative pl-4 mt-1">
                    <span className="absolute left-0 top-0">-</span>
                    <span dangerouslySetInnerHTML={{ __html: item }}></span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className=" mt-4 w-full md:w-[80%] text-justify mx-auto">
            <div className="py-10 px-4 md:px-10">
              <h3 className="text-primary font-bold">Qualification</h3>
              <ul className="list-none">
                {qualification.map((item, index) => (
                  <li key={index} className="relative pl-4 mt-1">
                    <span className="absolute left-0 top-0">-</span>
                    <span dangerouslySetInnerHTML={{ __html: item }}></span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {timing && (
            <div className=" mt-4 w-full md:w-[80%] text-justify mx-auto">
              <div className="py-10 px-4 md:px-10">
                <h3 className="text-primary font-bold">Working Hours</h3>
                {timing.map((item, index) => (
                  <p key={index} className="mt-1">
                    {item}
                  </p>
                ))}
              </div>
            </div>
          )}
          {niceToHave && (
            <div className=" mt-4 w-full md:w-[80%] text-justify mx-auto">
              <div className="py-10 px-4 md:px-10">
                <h3 className="text-primary font-bold">Nice to Have</h3>
                <ul className="list-none">
                  {niceToHave.map((item, index) => (
                    <li key={index} className="relative pl-4 mt-1">
                      <span className="absolute left-0 top-0">-</span>
                      <span dangerouslySetInnerHTML={{ __html: item }}></span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {additional && (
            <div className=" mt-4 w-full md:w-[80%] text-justify mx-auto">
              <div className="py-10 px-4 md:px-10">
                <h3 className="text-primary font-bold">
                  Additional Preferences
                </h3>

                <ul className="list-none"></ul>
                {additional.map((item, index) => (
                  <li key={index} className="relative pl-4 mt-1">
                    <span className="absolute left-0 top-0">-</span>
                    <span dangerouslySetInnerHTML={{ __html: item }}></span>
                  </li>
                ))}
              </div>
            </div>
          )}
          {benefits && (
            <div className="mt-4 w-full md:w-[80%] text-justify mx-auto">
              <div className="py-10 px-4 md:px-10">
                <h3 className="text-primary font-bold">Benefits/Perks</h3>
                {benefits.map((item, index) => (
                  <div key={index}>
                    <p className="mt-1">{item.title}</p>
                    <ul className="list-none">
                      {item.list.map((item, index) => (
                        <li key={index} className="relative pl-4 mt-1">
                          <span className="absolute left-0 top-0">-</span>
                          <span
                            dangerouslySetInnerHTML={{ __html: item }}
                          ></span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="container mt-8">
          <h1 className="text-4xl md:text-6xl font-bold text-primary mb-4">
            Ready to join?
          </h1>
          <ApplyForm title={title} btnColor="!bg-primary text-white" />
        </div>
        <div className="container">
          <div className="mt-4">
            <h1 className="text-5xl font-normal  text-primary mb-4">
              Find more job openning
            </h1>
          </div>
          <div className="w-full">
            <Slider
              slidesPerView={1}
              spaceBetween={10}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
              sliderClass="swiper1 mt-20"
            >
              {sliderData &&
                sliderData.length > 0 &&
                sliderData.map((value, key) => {
                  return (
                    <SwiperSlide key={key}>
                      <a
                        href={value.link}
                        key={key}
                        className="relative bg-white w-full rounded-30 group flex flex-col justify-between h-32"
                      >
                        <h3 className="text-2xl font-normal px-8 py-4">
                          {value.title}
                        </h3>
                        <img
                          src="/assets/icon/arrow.svg"
                          className="w-[22px] ml-auto group-hover:scale-125 !transition-all !ease-linear mr-8 mb-4"
                          alt="arrow"
                        />
                      </a>
                    </SwiperSlide>
                  );
                })}
            </Slider>
          </div>
          <div className="float-right pr-2 mt-8">
            <Link to={"/career?to=job"} className="text-primary group">
              <div className="flex">
                <p className="text-primary font-medium">Find More</p>
                <img
                  src="/assets/icon/arrow2.svg"
                  className="ml-2 mt-1 group-hover:translate-x-2 !transition-all !ease-linear"
                  alt="arrow"
                />
              </div>
            </Link>
          </div>
        </div>
      </section>
      <section className="bg-primary py-20">
        <div className="container">
          <div className="mt-10">
            <h1 className=" text-4xl md:text-5xl  font-normal  text-white mb-8">
              Didn't Find the Perfect Fit? Let's Create It Together! 
            </h1>
            <ContactForm
              cv={true}
              placeholderEmail="Email (Required)"
              placeholderText="Tell us what you do (Required)"
              btnColor="!bg-secondary text-white"
            />
          </div>
        </div>
      </section>

      {/* <Footer/> */}
    </>
  );
};
export default JobApply;
