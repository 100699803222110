import React from "react";
import EastIcon from "@mui/icons-material/East";
import { Link } from "react-router-dom";

const Button = (props) => {
  const { type, customClass, action, url, arrow, innerCustomClass } = props;

  return (
    <React.Fragment>
      {type === "arrow-btn" ? (
        <button
          onClick={action}
          className={`bg-whiteSmoke !transition-all rounded-51 text-secondary px-6 py-2 inline-flex items-center group ${customClass}`}
        >
          <span className="text-lg group-hover:scale-110 font-medium mr-2 !transition-all ease-out duration-300">
            {props.children}
          </span>
          <EastIcon className="group-hover:translate-x-2 !transition-all ease-out duration-300" />
        </button>
      ) : (
        <Link
          to={url}
          className={`bg-whiteSmoke rounded-51 text-secondary  px-6 py-3 ${customClass}`}
        >
          <span
            className={`font-bold group-hover:scale-110 !transition-all ease-out duration-500 ${innerCustomClass}`}
          >
            {props.children}
          </span>
          {arrow && (
            <EastIcon className="ml-2 group-hover:translate-x-2 !transition-all ease-out duration-300" />
          )}
        </Link>
      )}
    </React.Fragment>
  );
};
export default Button;
