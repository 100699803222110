// Loader.js
import React from "react";

const Loader = () => {
  return (
    <div className="loader">
      <div className="spinner"></div>
      <style>{`
        .loader {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          background: rgba(255, 255, 255, 0.8);
          z-index: 9999;
        }
        .spinner {
          border: 8px solid #f3f3f3;
          border-radius: 50%;
          border-top: 8px solid #271e1c;
          width: 60px;
          height: 60px;
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
};

export default Loader;
