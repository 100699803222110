import CountUp from "react-countup";
const CounterCard = (props) => {
  const { cardColor, number, description, suffix } = props;
  return (
    <div
      data-aos="fade-up"
      className={`rounded-[55px] w-[300px] lg:min-w-[500px]  inline-flex justify-center items-center py-10 gap-x-6 ${
        cardColor === "primary"
          ? "bg-primary text-white"
          : cardColor === "white"
          ? "bg-white text-secondary"
          : cardColor === "dark"
          ? "bg-secondary text-white"
          : "bg-transparent border  border-primary text-white"
      }`}
    >
      <CountUp
        start={0}
        end={number}
        duration={3}
        suffix={suffix}
        enableScrollSpy={true}
        scrollSpyDelay={0}
      >
        {({ countUpRef }) => (
          <span
            ref={countUpRef}
            className={`${
              cardColor === "primary" ? "text-white" : "text-primary"
            } font-semibold lg:text-5xl text-3xl`}
          />
        )}
      </CountUp>
      <span className="lg:text-xl text-sm font-normal">{description}</span>
    </div>
  );
};

export default CounterCard;
