const IconList = (props) =>{
    const {icon, heading, description , type} = props;
    return(
        <div data-aos="fade-up" className={`flex text-center md:text-start ${type === "vertical" ? 'flex-col' : 'lg:gap-28 gap-6 flex-col lg:flex-row'}`}>
            <div className={`flex-none m-auto md:m-[unset] ${type === "vertical" ? 'w-[54px] h-[54px]' : 'w-[44px] h-[44px]'}`}>
                <img className="w-full h-full object-contain" src={icon} alt="img"/>
            </div>
            <h4 className={`text-white ${type === "vertical" ? 'lg:mt-8 mt-6' : 'md:text-2xl text-xl md:leading-8 leading-6 font-sans font-bold text-whiteSmoke flex-none lg:basis-[154px] basis-3'}`}>{heading}</h4>
            <p className={`font-normal md:text-xl text-sm  ${type === "vertical" ? 'lg:mt-8 mt-6 text-white/50' : 'md:text-xl text-whiteSmoke font-sans text-sm font-normal'}`}>{description}</p>
        </div>
        
    )
}

      
      export default IconList;