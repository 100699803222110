import React, { useState } from "react";
import { TECollapse } from "tw-elements-react";

const Accordion = (props) => {
  const { heading, index } = props;
  const [activeElement, setActiveElement] = useState("");

  const handleClick = (value) => {
    if (value === activeElement) {
      setActiveElement("");
    } else {
      setActiveElement(value);
    }
  };

  return (
    <>
      <div id="accordionExample">
        <div className="rounded-none border border-l-0 border-r-0 border-t-0 border-neutral-200">
          <h2 className="mb-0" id="headingOne">
            <button
              className={`${
                activeElement === index &&
                `[box-shadow:inset_0_1px_0_rgba(229,231,235)]`
              } group relative flex w-full items-center font-medium rounded-none border-0 px-5 py-6 text-left text-2xl transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none`}
              type="button"
              onClick={() => handleClick(index)}
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              {heading}
              <span
                className={`${
                  activeElement === index
                    ? `rotate-[-180deg] -mr-1`
                    : `rotate-0 fill-[#212529]`
                } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </button>
          </h2>
          <TECollapse
            show={activeElement === index}
            className="!mt-0 !rounded-b-none !shadow-none"
          >
            <div className="px-5 pt-4 pb-8">{props.children}</div>
          </TECollapse>
        </div>
      </div>
    </>
  );
};

export default Accordion;
