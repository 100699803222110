import React from "react";
import { Swiper } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import {
  FreeMode,
  Pagination,
  Autoplay,
  EffectFade,
  Mousewheel,
  EffectCreative,
} from "swiper/modules";

const Slider = (props) => {
  const {
    slidesPerView,
    direction,
    spaceBetween,
    freeMode,
    autoplay,
    pagination,
    sliderClass,
    centeredSlides,
    breakpoints,
    effect,
    loop,
    speed,
    autoHeight,
    mousewheel,
    grabCursor,
    creativeEffect,
  } = props;

  return (
    <Swiper
      grabCursor={grabCursor}
      direction={direction}
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      freeMode={freeMode}
      loop={loop}
      breakpoints={breakpoints}
      effect={effect}
      fadeEffect={{ crossFade: true }}
      creativeEffect={creativeEffect}
      speed={speed}
      autoHeight={autoHeight}
      autoplay={autoplay}
      pagination={pagination}
      centeredSlides={centeredSlides}
      mousewheel={mousewheel}
      modules={[
        FreeMode,
        Pagination,
        Autoplay,
        EffectFade,
        Mousewheel,
        EffectCreative,
      ]}
      className={`${sliderClass}`}
      // onSlideChange={handleSlideChange}
      // onMousewheel={handleMousewheel}
    >
      {props.children}
    </Swiper>
  );
};

export default Slider;
