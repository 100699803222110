import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({ title, description, keywords, ogTitle, ogImage }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href="https://tygrlabs.co/" />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content="https://www.tygrlabs.co/" />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={ogTitle} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content="https://tygrlabs.co/assets/images/Y_TygrLabs.png"
      />
      <meta name="robots" content="index, follow" />
      <meta name="author" content="TygrLabs" />
    </Helmet>
  );
};

export default SEO;
