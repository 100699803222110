import { Link, useLocation, NavLink } from "react-router-dom";
import Button from "./button";
import React, { useEffect, useState } from "react";
import { RxHamburgerMenu, RxCross1 } from "react-icons/rx";

const Header = (props) => {
  const location = useLocation();
  const [scrollState, setScrollState] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [active, setActive] = useState("");
  const [navType, setNavType] = useState(false);
  const routes = ["/apply", "/privacy", "/terms-and-service"];
  useEffect(() => {
    if (routes.includes(location.pathname)) {
      setNavType(true);
    } else {
      setNavType(false);
    }
  }, [useLocation().pathname]);

  const navItems = [
    {
      title: "About Us",
      url: "/about",
    },
    {
      title: "Career",
      url: "/career",
    },
  ];

  useEffect(() => {
    const listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 30) {
        setScrollState("scroll");
      } else {
        setScrollState("");
      }
    });

    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, [scrollState]);

  ["load", "resize"].forEach((event) => {
    document.addEventListener(event, () => {
      if (openMenu && window.innerWidth < 767) {
        document
          .querySelector("body")
          .classList.add("overflow-hidden h-screen");
      } else {
        document
          .querySelector("body")
          .classList.remove("overflow-hidden h-auto");
      }
    });
  });

  return (
    <>
      {navType ? (
        <nav
          className={`w-full inset-x-0 fixed top-0 z-50 bg-secondary/80 backdrop-blur ${
            scrollState || "w-full inset-x-0 fixed top-0 z-50 !bg-transparent"
          } shadow-md md:shadow-none`}
        >
          <div className={`flex items-center container z-0 py-6 `}>
            {scrollState ? (
              <Link
                to="/"
                onClick={() => {
                  setActive(undefined);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <img src="assets/images/Logo.svg" alt="logo-black" />
              </Link>
            ) : (
              <Link
                to="/"
                onClick={() => {
                  setActive(undefined);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <img src="assets/images/Logo-black.svg" alt="logo-black" />
              </Link>
            )}

            <ul
              className={`${
                openMenu ? "top-[72px]" : "top-[-120vh]"
              } z-[-1] md:z-0 0 h-screen md:h-auto flex py-5 md:py-0 flex-col md:flex-row absolute md:relative w-full inset-x-0 bg-secondary md:bg-transparent text-white md:top-0  md:justify-end ${
                scrollState ||
                "py-5 md:py-0 flex-col md:flex-row absolute md:relative w-full inset-x-0 bg-secondary md:bg-transparent md:!text-secondary text-whiteSmoke md:top-0  md:justify-end"
              }`}
            >
              {navItems &&
                navItems.length > 0 &&
                navItems.map((item, key) => {
                  return (
                    <li key={key}>
                      <NavLink
                        onClick={() => setOpenMenu(!openMenu)}
                        to={item.url}
                        className={({ isActive }) =>
                          `${
                            isActive
                              ? "md:border-primary text-whiteSmoke !transition-none"
                              : "border-transparent"
                          } block !transition-all ease-out duration-500 md:hover:scale-110 md:border-b-2 md:mr-[53px] px-4 py-3 md:p-0 nav-item relative hover:before:w-full text-center md:text-start`
                        }
                      >
                        {item.title}
                      </NavLink>
                    </li>
                  );
                })}
              <li className="text-center  font-bold mt-0 md:hidden px-4 py-3 md:p-0 nav-item relative hover:before:w-full">
                <Link
                  className="bg-whiteSmoke rounded-51 text-secondary px-6 py-2 inline-flex items-center"
                  onClick={() => {
                    setActive(undefined);
                  }}
                  to="/?to=contact"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
            <Button
              customClass={`bg-whiteSmoke text-black flex-none hidden md:inline-flex ${
                scrollState ||
                "flex-none hidden md:inline-flex !bg-primary text-white"
              }`}
              url="/?to=contact"
            >
              Contact Us
            </Button>
            {openMenu ? (
              <RxCross1
                onClick={() => setOpenMenu(!openMenu)}
                className={`text-3xl md:hidden ml-auto ${
                  scrollState ? "text-alabaster" : "text-black"
                }`}
              />
            ) : (
              <RxHamburgerMenu
                onClick={() => setOpenMenu(!openMenu)}
                className={`text-3xl md:hidden ml-auto ${
                  scrollState ? "text-alabaster" : "text-black"
                }`}
              />
            )}
          </div>
        </nav>
      ) : (
        <nav
          className={`w-full inset-x-0 fixed top-0 z-50  bg-secondary/80 backdrop-blur ${
            scrollState || "w-full inset-x-0 fixed top-0 z-50  !bg-transparent"
          }`}
        >
          <div className={`flex items-center container z-0 py-6 `}>
            <Link
              to="/"
              onClick={() => {
                setOpenMenu(false);
                setActive(undefined);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <img src="assets/images/Logo.svg" alt="logo" />
            </Link>

            <ul
              className={`${
                openMenu ? "top-[72px]" : "top-[-120vh]"
              } z-[-1] md:z-0 h-screen md:h-auto flex py-5 md:py-0 flex-col md:flex-row absolute md:relative w-full inset-x-0 bg-secondary md:bg-transparent text-white md:text-whiteSmoke  md:top-0  md:justify-end`}
            >
              {navItems &&
                navItems.length > 0 &&
                navItems.map((item, key) => {
                  return (
                    <li key={key}>
                      <NavLink
                        onClick={() => setOpenMenu(!openMenu)}
                        className={({ isActive }) =>
                          `${
                            isActive
                              ? "md:border-primary text-whiteSmoke"
                              : "border-transparent"
                          } block md:hover:scale-110 !transition-all ease-out duration-500 md:border-b-2 md:mr-[53px] px-4 py-3 md:p-0 nav-item relative hover:before:w-full text-center md:text-start`
                        }
                        to={item.url}
                      >
                        {item.title}
                      </NavLink>
                    </li>
                  );
                })}
              <li className="text-center font-bold mt-0 px-4 py-3 md:hidden md:p-0 nav-item relative hover:before:w-full">
                <Link
                  to="/?to=contact"
                  className="bg-whiteSmoke rounded-51 text-secondary px-6 py-2 inline-flex items-center"
                  onClick={() => setOpenMenu(!openMenu)}
                >
                  Contact Us
                </Link>
              </li>
            </ul>

            <Button
              customClass="flex-none hidden md:inline-flex"
              url="/?to=contact"
            >
              Contact Us
            </Button>

            {openMenu ? (
              <RxCross1
                onClick={() => setOpenMenu(!openMenu)}
                className="text-white text-3xl md:hidden ml-auto"
              />
            ) : (
              <RxHamburgerMenu
                onClick={() => setOpenMenu(!openMenu)}
                className="text-white text-3xl md:hidden ml-auto"
              />
            )}
          </div>
        </nav>
      )}
    </>
  );
};
export default Header;
