import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="bg-secondary md:py-20 py-10">
      <div className="container">
        <div className="grid grid-cols-4 md:gap-12 gap-3">
          <ul className="col-span-full md:col-span-1">
            <li className="font-medium text-base text-primary">USA</li>
            <li className="font-normal text-base text-whiteSmoke mt-4">
              1717 Main Street, 56th Floor
              <br /> Dallas, TX 75201
            </li>
            {/* <li className="font-normal text-base text-whiteSmoke mt-4">
              <Link to="tel:+1 214 574 5900">1 214 574 5900</Link>
            </li> */}
          </ul>
          <ul className="col-span-full md:col-span-1">
            <li className="font-medium text-base text-primary">Bangladesh</li>
            <li className="font-normal text-base text-whiteSmoke mt-4">
              Level 5, Plot No 11, Rd No 113/A,
              <br /> Gulshan-2, Dhaka 1212
            </li>
            {/* <li className="font-normal text-base text-whiteSmoke mt-4">
              <Link to="tel:+8801841732878">880 184 173 2878</Link>
            </li> */}
          </ul>
          <ul className="col-span-full md:col-span-1">
            <li className="font-medium text-base text-primary">Nepal</li>
            <li className="font-normal text-base text-whiteSmoke mt-4">
              Level&nbsp;2,&nbsp;Kamalpokhari&nbsp;St 1,
              <br />
              Kathmandu 44600
            </li>
          </ul>
        </div>
        <div className="flex sm:flex-row flex-col-reverse justify-between mt-20 md:gap-14 sm:gap-7 gap-3">
          <div className="flex sm:flex-row flex-col-reverse lg:gap-14 sm:gap-5 gap-3">
            <p className="font-medium text-base text-whiteSmoke">
              &copy; 2024 TygrLabs Ltd.
            </p>
            <Link to="/privacy">
              <p className="font-medium text-base text-whiteSmoke">
                Privacy Notice
              </p>
            </Link>
            <Link to="/terms-and-service">
              <p className="font-medium text-base text-whiteSmoke">
                Website Terms of Use
              </p>
            </Link>
          </div>
          <div className="flex sm:gap-7 gap-3 sm:mb-0 mb-4">
            <a
              href="https://www.facebook.com/profile.php?id=61562120561507&mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="assets/icon/fb.svg"
                alt=""
                className="w-4 h-4 sm:w-auto sm:h-auto"
              />
            </a>
            <a
              href="https://www.instagram.com/tygr.labs?igsh=bGl0dmdmYzExMnN4"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="assets/icon/insta.svg"
                alt=""
                className="w-4 h-4 sm:w-auto sm:h-auto"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/tygrlabs/posts/?feedView=all"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="assets/icon/linkedin.svg"
                alt=""
                className="w-4 h-4 sm:w-auto sm:h-auto"
              />
            </a>
            {/* <a href="#">
              <img
                src="assets/icon/twitter.svg"
                alt=""
                className="w-4 h-4 sm:w-auto sm:h-auto"
              />
            </a> */}
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
