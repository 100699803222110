import React from "react";
import { useEffect } from "react";

export const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="py-24 md:py-32 bg-[#EEEEEE]">
        <div className="container mx-auto">
          <div className="text-center mb-10">
            <h1 className="text-3xl md:text-5xl text-left font-bold text-primary">
              Terms of Service
            </h1>
          </div>
          <div className="md:mx-auto md:w-3/4">
            <div className="mb-8">
              <h2 className="text-xl md:text-2xl text-left font-bold text-secondary">
                Welcome to TygrLabs.
              </h2>
              <p className="mt-4 text-base md:text-lg text-gray-800">
                These terms and conditions lay out the rules and guidelines for
                using TygrLabs’s website.
                <br />
                <br />
                TygrLabs Bangladesh Limited (TygrLabs) is situated at:
                <br />
                <br />
                <span className="font-bold">
                  Level 5, Plot No 11, Rd No 113/A, Gulshan-2, Dhaka 1212 <br />
                  (Registered address)
                </span>
                <br />
                <br />
                By entering this website, we assume you agree to these terms and
                conditions. If you do not agree to all the terms and conditions
                stated here, please refrain from using TygrLabs’s website.
                <br />
                <br />
                The language used in these Terms and Conditions, Privacy
                Statement, and Disclaimer Notice, such as “Client”, “You”, and
                “Your”, refers to you - the person using this website and
                accepting our terms. Phrases like “The Company”, “Ourselves”,
                “We”, “Our”, and “Us” refer to our company. Terms like “Party”,
                “Parties”, or “Us” refer to both you and us, or either you or
                us. These terms signify the offer, acceptance, and payment
                necessary for us to provide services to you, as well as any
                agreements made between us. They are subject to the laws of
                Bangladesh. Any words like “singular”, “plural”,
                “capitalization”, or “he/she” are interchangeable and mean the
                same thing.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="text-lg md:text-xl font-bold text-left text-primary">
                License
              </h2>
              <p className="mt-4 text-base md:text-lg text-gray-800">
                Unless otherwise stated, TygrLabs and/or its licensors own the
                intellectual property rights for all material on TygrLabs's
                website. All intellectual property rights are reserved. You may
                view and/or print pages from https://tygrlabs.co for your own
                personal use subject to restrictions set in these terms and
                conditions.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="text-lg md:text-xl font-bold text-left text-primary">
                Cookies
              </h2>
              <p className="mt-4 text-base md:text-lg text-gray-800">
                We employ the use of cookies. By accessing TygrLabs's website,
                you agreed to use cookies in agreement with TygrLabs's privacy
                policy.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="text-lg md:text-xl font-bold text-left text-primary">
                Hyperlinking to Our Content
              </h2>
              <p className="mt-4 text-base md:text-lg text-gray-800">
                The following organizations may link to our website without
                prior written approval:
                <ul className="list-disc pl-6">
                  <li>Government agencies</li>
                  <li>Search engines</li>
                  <li>News organizations</li>
                  <li>Online directory distributors</li>
                  <li>Systemwide Accredited Businesses</li>
                </ul>
              </p>
            </div>
            <div className="mb-8">
              <h2 className="text-lg md:text-xl text-left font-bold text-primary">
                Hyperlinking to Our Content
              </h2>
              <div className="mt-4 text-base md:text-lg text-gray-800">
                <ol className="list-decimal pl-6">
                  <li>
                    <p>
                      The following organizations may link to our Website
                      without prior written approval:
                    </p>
                    <ul className="list-disc pl-6">
                      <li>Government agencies</li>
                      <li>Search engines</li>
                      <li>News organizations</li>
                      <li>
                        Online directory distributors when listing us in their
                        directory, following the same standards as they do for
                        other businesses
                      </li>
                      <li>
                        Systemwide Accredited Businesses, except for non-profit
                        organizations, charity shopping malls, and charity
                        fundraising groups, which may not hyperlink to our
                        Website.
                      </li>
                    </ul>
                  </li>
                </ol>
                <ol start="2" className="list-decimal pl-6">
                  <li>
                    <p>
                      These organizations may link to our home page,
                      publications, or other website information under the
                      following conditions:
                    </p>
                    <ul className="list-disc pl-6">
                      <li>The link should not be misleading.</li>
                      <li>
                        It must not falsely imply sponsorship, endorsement, or
                        approval of the linking party and its products or
                        services.
                      </li>
                      <li>
                        The link should fit within the context of the linking
                        party’s site.
                      </li>
                    </ul>
                  </li>
                </ol>
                <ol start="3" className="list-decimal pl-6">
                  <li>
                    <p>
                      We may consider and approve link requests from other types
                      of organizations, including:
                    </p>
                    <ul className="list-disc pl-6">
                      <li>
                        Commonly-known consumer and/or business information
                        sources
                      </li>
                      <li>Dot.com community sites</li>
                      <li>Associations or groups representing charities</li>
                      <li>Online directory distributors</li>
                      <li>Internet portals</li>
                      <li>
                        Accounting, law, and consulting firms primarily serving
                        businesses
                      </li>
                      <li>Educational institutions and trade associations.</li>
                    </ul>
                  </li>
                </ol>
                <p>
                  We will approve link requests from these organizations if:
                </p>
                <ul className="list-disc pl-6">
                  <li>
                    The link would not reflect unfavorably on us or our
                    accredited businesses.
                  </li>
                  <li>
                    The organization does not have an unsatisfactory record with
                    us.
                  </li>
                  <li>
                    The benefit to us from the visibility associated with the
                    hyperlink outweighs the absence of TygrLabs Bangladesh
                    Limited.
                  </li>
                  <li>
                    The link is in the context of general resource information
                    or is consistent with editorial content in a newsletter or
                    similar product furthering the mission of the organization.
                  </li>
                </ul>
                <p>
                  If your organization falls into the categories mentioned above
                  and wishes to link to our website, please notify us by sending
                  an email to{" "}
                  <a href="mailto:hello@tygrlabs.co" className="text-blue-500">
                    hello@tygrlabs.co
                  </a>
                  . Include your name, organization name, contact information,
                  the URL of your site, a list of URLs from which you intend to
                  link to our Website, and the URLs on our site you would like
                  to link to. Please allow 2-3 weeks for a response.
                </p>
                <p>
                  Approved organizations may hyperlink to our Website as
                  follows:
                </p>
                <ul className="list-disc pl-6">
                  <li>By use of our corporate name; or</li>
                  <li>
                    By use of the uniform resource locator (Web address) being
                    linked to; or
                  </li>
                  <li>
                    By use of any other description of our Website or material
                    being linked to that makes sense within the context and
                    format of content on the linking party’s site.
                  </li>
                </ul>
                <p>
                  No use of TygrLabs’s logo or other artwork will be allowed for
                  linking absent a trademark license agreement.
                </p>
              </div>
            </div>
            <div className="mb-8">
              <h2 className="text-lg md:text-xl font-bold text-left text-primary">
                Iframes
              </h2>
              <p className="mt-4 text-base md:text-lg text-gray-800">
                You are not permitted to create frames around our web pages or
                utilize other techniques that alter the visual presentation or
                appearance of our website without prior approval and express
                written permission.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="text-lg md:text-xl font-bold text-left text-primary">
                Reservation of Rights
              </h2>
              <p className="mt-4 text-base md:text-lg text-gray-800">
                We reserve the right, at any time and in our sole discretion, to
                request the removal of all links or any particular link to our
                website. You agree to promptly remove all links to our website
                upon such a request. Additionally, we reserve the right to amend
                these terms and conditions and our linking policy at any time.
                By continuing to link to our website, you agree to be bound by
                and adhere to these linking terms and conditions.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="text-lg md:text-xl font-bold text-left text-primary">
                Removal of Links from Our website
              </h2>
              <p className="mt-4 text-base md:text-lg text-gray-800">
                If you discover any objectionable link on our website or any
                linked website, you may contact us. While we will consider
                requests to remove links, we are under no obligation to do so or
                to respond directly to you.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="text-lg md:text-xl font-bold text-left text-primary">
                Content Liability
              </h2>
              <p className="mt-4 text-base md:text-lg text-gray-800">
                We assume no responsibility or liability for any content
                appearing on your website. By agreeing to these terms, you agree
                to indemnify and defend us against all claims arising from or
                based on your website. Links may not appear on any page of your
                website or within any context containing content or materials
                that may be interpreted as libelous, obscene, or criminal, or
                which infringes upon, otherwise violates, or advocates the
                infringement or.
              </p>
            </div>

            {/* Add more sections as needed */}
            <div className="mb-8">
              <h2 className="text-lg md:text-xl font-bold text-left text-primary">
                Disclaimer
              </h2>
              <p className="mt-4 text-base md:text-lg text-gray-800">
                To the maximum extent permitted by applicable law, we exclude
                all representations, warranties, and conditions relating to our
                website and the use of this website (including, without
                limitation, any warranties implied by law in respect of
                satisfactory quality, fitness for purpose, and/or the use of
                reasonable care and skill). Nothing in this disclaimer will:
              </p>
              <ul className="list-disc ml-8 mt-2 text-base md:text-lg text-gray-800">
                <li>
                  limit or exclude our or your liability for death or personal
                  injury resulting from negligence;
                </li>
                <li>
                  limit or exclude our or your liability for fraud or fraudulent
                  misrepresentation;
                </li>
                <li>
                  limit any of our or your liabilities in any way that is not
                  permitted under applicable law; or
                </li>
                <li>
                  exclude any of our or your liabilities that may not be
                  excluded under applicable law.
                </li>
              </ul>
              <p className="mt-4 text-base md:text-lg text-gray-800">
                The limitations and exclusions of liability set out in this
                Section and elsewhere in this disclaimer: (a) are subject to the
                preceding paragraph; and (b) govern all liabilities arising
                under the disclaimer or in relation to the subject matter of
                this disclaimer, including liabilities arising in contract, in
                tort (including negligence), and for breach of statutory duty.
              </p>
              <p className="mt-4 text-base md:text-lg text-gray-800">
                To the extent that the website and the information and services
                on the website are provided free of charge, we will not be
                liable for any loss or damage of any nature.
              </p>
            </div>
            <div className="mb-8">
              <h2 className="text-lg md:text-xl font-bold text-left text-primary">
                Credit & Contact Information:
              </h2>
              <p className="mt-4 text-base md:text-lg text-gray-800">
                This Terms and Conditions page was generated using the
                termsandconditionstemplate.com generator. If you have any
                queries regarding any of our terms, please contact us.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
